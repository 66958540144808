$bg-color: hsl(200, 33%, 96%);

// Palette :

$skyscend-color: #00aeef;
$secondary-color: #414750;
$tertiary-color: #fff;
$accent-color: #ed217c;
$border-color: #22222325;

$accordion-color: #fafafa;

// Fonts :

$font-body: "Open Sans";

$font-dark: #222;
$font-grey: #414750;
$font-light: #fafafa;
$font-error: #d2180f;

$link-color: #0384cf;

$disabled-text: rgba(97, 97, 97, 0.8);
