@import "_variables.scss";

.po-invoice-container {
	.upload-card {
		.invoice-dropzone {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
			margin-bottom: 40px;
			height: 130px;
			border: 2px dashed $skyscend-color;
			border-radius: 7px;
			&.holding-files {
				background-color: #f5f5f5;
			}
			&:hover {
				cursor: pointer;
				background-color: #f5f5f5;
			}
			div {
				margin-top: 15px;
				.dropzone-content {
					font-weight: bold;
				}
			}
		}

		aside {
			h4 {
				margin-bottom: 25px;
			}
			.list-group {
				margin-bottom: 30px;
				.list-group-item {
					display: flex;
					justify-content: space-between;
					padding: 0.5rem 1.25rem;
					span {
						align-self: center;
					}
					.files-ready-delete {
						align-self: center;
						text-decoration: none;
						color: rgba(255, 0, 0, 0.6) !important;

						span span:first-child {
							visibility: hidden;
						}

						&:hover,
						&:focus {
							color: red !important;
							span span:first-child {
								display: inline-block;
								margin-right: 0.75rem;
								animation: slide-left 0.3s ease-in-out forwards;
							}
						}
						&:focus {
							box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.5);
						}
					}
				}
			}
		}
		.row {
			justify-content: flex-end;
		}
	}

	@keyframes slide-left {
		from {
			transform: translateX(150%);
			color: rgba(255, 0, 0, 0.6);
			opacity: 0;
		}
		60% {
			opacity: 0;
		}
		to {
			transform: translateX(0%);
			color: red;
			opacity: 1;
			visibility: visible;
		}
	}
}

#refresh,
#upload-card {
	scroll-margin-top: 120px;
}
