@import "_variables.scss";

.topbar {
	width: -webkit-fill-available;
	z-index: 3;
	height: 3.2rem;
	top: 0 !important;
	margin-left: 1rem;
	margin-right: 1rem;
	padding: 0 8px 0 8px !important;

	background-color: $secondary-color;
	border: 2px solid $border-color;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;

	a {
		color: $font-light !important;
		text-decoration: none !important;
		&:hover {
			filter: brightness(0.9);
		}
	}

	.profile-dropdown .dropdown-menu {
		left: unset;
		right: 0;
		z-index: 100;
		.profile-dropdown-hello {
			pointer-events: none;
			cursor: default;
		}
		a {
			color: $font-grey !important;
			&:active {
				background-color: $skyscend-color;
				color: white !important;
			}
			&:hover {
				filter: brightness(0.95);
			}
		}
	}
}

.topbar-signup {
	align-self: baseline;
	width: -webkit-fill-available;
	z-index: 2;
	padding: 4px 9px 4px 8px !important;

	background-color: $secondary-color;

	.navbrand-signup {
		display: inline-block;
		height: unset;
		margin-bottom: unset;
	}
	a {
		color: $font-light !important;
		&:hover {
			filter: brightness(0.9);
		}
	}
	span a {
		text-decoration: none !important;
	}
}

#topbarSearchField {
	height: 2rem;
	border-radius: 1px;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	&:focus {
		border: none;
		box-shadow: 0 0 0 0.1rem $bg-color !important;
	}
}

#topbarSearchBtn {
	z-index: 4;
	height: 2rem;
	width: 2rem;
	border-radius: 2px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	display: flex;
	svg {
		margin-top: 1.2px;
		transform: scale(2.2);
	}
	&:focus {
		box-shadow: 0 0 0 0.1rem $bg-color !important;
	}
}

.orgListTable {
	border: 1px solid $border-color;
	border-radius: 1rem;
}
