.userList-card,
.userDetails-card {
	.nav-tabs {
		border-bottom: 1px solid #dee2e6 !important;
	}
	.tab-content {
		margin-top: 50px;
	}
}


