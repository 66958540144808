/* OPEN SANS */

/* open-sans-300 - latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 300;
	src: local("Open Sans Light"), local("OpenSans-Light"),
		url("../fonts/Open Sans/open-sans-v17-latin-300.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/Open Sans/open-sans-v17-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Open Sans Regular"), local("OpenSans-Regular"),
		url("../fonts/Open Sans/open-sans-v17-latin-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/Open Sans/open-sans-v17-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
		url("../fonts/Open Sans/open-sans-v17-latin-600.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/Open Sans/open-sans-v17-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	src: local("Open Sans Bold"), local("OpenSans-Bold"),
		url("../fonts/Open Sans/open-sans-v17-latin-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/Open Sans/open-sans-v17-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 800;
	src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
		url("../fonts/Open Sans/open-sans-v17-latin-800.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/Open Sans/open-sans-v17-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
