@import "_variables.scss";

$navbar-font-color: $font-grey;

button.sidebarHider {
	display: flex;
	justify-content: flex-end;

	position: fixed;
	width: 6rem;
	z-index: 3;
	top: 6.2rem;
	left: 0;
	background-color: #00aeefbb !important;

	border: 1.5px solid #22222344;
	border-left-width: 0;
	border-radius: 1.5px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;

	padding: 7px 12px 7px 8px;
	&:active,
	&:hover {
		color: $font-light !important;
		border: 1.5px solid $secondary-color !important;
		border-left-width: 0 !important;
	}

	svg.sidebar-close {
		transition: transform 0.1s ease-in-out;
	}
	&:hover svg.sidebar-close {
		transform: rotate(-90deg);
	}

	transition: width 0.3s linear, background-color 0.3s linear;
	&.hidden {
		width: 3rem;
		transition: width 0.3s linear, background-color 0.3s linear;
		background-color: #00aeefdd !important;
		border: 1.75px solid $secondary-color !important;
		border-left-width: 0 !important;
		&:hover {
			animation: menuAnimation 0.1s forwards;
			animation-timing-function: ease-in;
		}
	}

	&:focus {
		outline: 2px solid black;
	}
}

@keyframes menuAnimation {
	to {
		width: 4rem;
	}
}

.sidebar-container {
	transition: width 0.3s linear;
	width: 18.75%;
	&.hidden {
		width: 0% !important;
		transition: width 0.3s linear;
	}
}

.sidebar-wrapper {
	display: flex;
	z-index: 2;
	position: fixed !important;
	min-height: 100vh !important;

	transition: transform 0.3s linear;

	&.hidden {
		transform: translateX(-100%);
		transition: transform 0.3s linear;
	}

	.sidebar {
		display: block;
		// Delete to push to side:
		margin: 5rem 1.5rem 1.5rem 1.5rem;
		padding-top: 5.5rem;
		padding-right: 0.9rem;
		padding-left: 0.9rem;
		width: 65%;

		border: 1.5px solid $border-color;
		border-radius: 3px;

		background-color: $tertiary-color;

		.sidebar-icon {
			margin-right: 0.75rem;
			margin-bottom: 0.255rem;
			font-size: 1.2rem;
		}

		a {
			color: $navbar-font-color !important;
			margin-bottom: 0.3rem;
			font-size: 1.1rem;
			font-weight: 600;
			padding-top: 4px;
			padding-bottom: 3px;
			width: 100% !important;

			&:hover {
				color: $skyscend-color !important;
			}

			&.nav-clicked {
				color: white !important;
				font-weight: 400;
				background-color: #414750ff;
				box-shadow: inset -0.35em 0 $skyscend-color;
			}			
		}
	
	}
}
