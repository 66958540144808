@import "_variables.scss";

.social-links-card {
	margin-top: 1.5rem;
	margin-left: 7vw;
	margin-right: 7vw;
	h4 {
		margin-bottom: 2rem;
	}

	.fb-profile-group {
		display: flex;

		span {
			height: 38px;
			button {
				&[disabled] {
					pointer-events: none;
				}

				border-radius: 0.15rem;

				font-size: 0.925rem;
				font-weight: 500;
				padding-bottom: 0.4rem;
				width: 15rem;
				i {
					transform: scale(1.1);
					margin-left: -0.1rem !important;
					margin-right: 0.7rem !important;
				}
				transition: filter 0.2s ease-in;
				&:hover {
					filter: opacity(0.9);
					transition: filter 0.2s ease-out;
				}
			}
		}
	}

	.google-profile-group {
		display: flex;
		margin-top: 1.5rem;
		& > button {
			display: flex;
			justify-content: center;
			height: 39px;
			width: 15rem;

			div {
				transform: scale(0.85);
			}
			transition: filter 0.2s ease-in;
			&:hover {
				filter: opacity(0.9);
				transition: filter 0.2s ease-out;
			}
		}
		span {
			height: 39px;
			font-size: 0.92rem;
			margin-right: 0.2rem !important;
		}
	}
}
