@import "_variables.scss";

// Global Defaults :

a {
	cursor: pointer;
	text-decoration: none;
	color: $link-color !important;
}

.span-link {
	cursor: pointer;
	font-weight: 400;
	font-size: 0.95rem;
	color: $disabled-text;
	&:hover {
		text-decoration: underline;
		color: $disabled-text;
	}
}

// Bootstrap + Globals :

.home-row {
	margin-top: -1.5rem;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.base-container.logged-in {
	display: flex;
	height: auto;
	margin-top: 6.5rem;
	margin-bottom: 5.5rem;
	@media (min-width: 992px) {
		max-width: 720px !important;
	}
	@media (min-width: 1200px) {
		max-width: 960px !important;
	}
	@media (min-width: 1440px) {
		max-width: 1140px !important;
	}
}

.base-container.login-base-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.accordion {
	.card {
		border-radius: 5px !important;
		.card-header {
			background-color: $accordion-color !important;
			border-bottom: 1px solid $border-color !important;
			h6 {
				font-size: 1.05rem;
			}
		}
	}
}

// (btns + paginate)
.btn-primary,
.page-item.active .page-link {
	z-index: 2;
	background-color: $skyscend-color !important;
	border: 1px solid $border-color !important;
	&:focus {
		box-shadow: 0 0 0 0.1rem $secondary-color !important;
	}
}

button.btn-link {
	color: $link-color !important;
	&:hover {
		color: $link-color !important;
	}
}

.page-link:focus {
	box-shadow: 0 0 0 0.1rem $secondary-color !important;
}

.btn-primary {
	transition: background-color 0s ease-in !important;
	&:hover {
		background-color: $accent-color !important;
		border-color: $accent-color;

		color: $font-light;
		transition: background-color 0s ease-in !important;
	}
	&:disabled {
		background-color: $skyscend-color !important;
	}
}

// Tabbed link color
.nav-item.nav-link {
	color: $link-color !important;
}

.col-set,
.container-set {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.row-set {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.pad-40 {
	padding: 40px;
}

.pad-50 {
	padding: 50px;
}

.card-shadow {
	box-shadow: 0 20px 61px -18px rgba(0, 0, 0, 0.37);
}

.card.card-rad {
	border-radius: 5px !important;
}

// forms
// FIXME: social-form-error is only used in sign up page
// FIXME: profile-form-error is only used in socialLinks

.form-error,
.social-form-error {
	margin-left: 0.3rem;
	margin-top: 0.6rem;

	color: $font-error !important;
	font-size: 0.9rem;
}

.form-error::before {
	display: inline;
	content: "⚠ ";
}

.social-form-error {
	text-align: center;
	margin-bottom: 0;
}

.form-error-inline {
	margin: 0;

	color: $font-error !important;
	font-size: 0.9rem;
}

.profile-error {
	margin-left: 0.3rem;
	margin-top: 1rem;
	margin-bottom: 0;
}

// Field Outlines
.form-control {
	&:focus {
		border: 2px solid $skyscend-color !important;
		box-shadow: none !important;
	}
	&.field-error {
		border: 2px solid red !important;
		box-shadow: none !important;
		// &:focus {
		// 	border: 2px solid $skyscend-color !important;
		// }
	}
}

.dark-table-header {
	background-color: #414750;
	color: $font-light;
	white-space: nowrap;
}

table.dividers {
	border-collapse: collapse;
	th {
		border: 1px solid #22222344;
	}
	td {
		border: 1px solid rgb(222, 226, 230);
		border-bottom: 0;
	}
	tr:first-child td,
	th {
		border-top: 0;
	}
	tr td:first-child,
	th:first-child {
		border-left: 0;
	}
	tr td:last-child,
	th:last-child {
		border-right: 0;
	}
}

.rejected {
	color: $font-error;
}

.button-spinner {
	margin-bottom: 2px !important;
	margin-left: 0.5rem !important;
}

.otpContainerStyle {
	justify-content: center;
}

.otpStyle {
	width: 2.25rem !important;
	height: 3rem;
	margin: 0 0.5rem;
	font-size: 1rem;
	border-radius: 0.25rem;
	border: 1.5px solid $border-color;
}

.otpErrorStyle {
	border: 2px solid red !important;
}

// react-toastify
.Toastify__toast--success {
	background-color: $skyscend-color !important;
	text-align: center;
}
.Toastify__toast--error {
	text-align: center;
}

// Modal

.modal {
	font-family: $font-body;
}

// Miscellaneous :

// Change Highlight Selection Colour
::selection {
	background: $accent-color;
	color: $font-light;
}
::-moz-selection {
	background: $accent-color;
	color: $font-light;
}

// Change Autofill Highlight Colour
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px #e1f3ff inset !important;
	-webkit-box-shadow: 0 0 0 30px #e1f3ff inset !important;
}

// .modal-backdrop {
// 	z-index: 10000;
// }
// .modal {
// 	z-index: 10000;
// }
